<template>
  <f7-page name="home">
    <f7-navbar title="Ayarlar" back-link @click="$f7router.back()">
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list simple-list class="no-margin">
      <f7-list-item>
        <span>Koyu mod</span>
        <f7-toggle :checked="darkMode" @toggle:change="toggleDarkMode"></f7-toggle>
      </f7-list-item>
      <f7-list-item>
        <span
          class="block-span"
          @click="clearSearchHistory"
        >Arama geçmişini temizle</span>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import RecentSearch from "../js/recent_searches";

export default {
  name: "Options",
  computed: {
    darkMode() {
      return !!this.$store.state.darkMode;
    }
  },
  methods: {
    toggleDarkMode() {
      this.$store.commit("setDarkMode", +!this.darkMode);
    },
    clearSearchHistory() {
      RecentSearch.save([]);
      const toast = this.$f7.toast.create({
        text: "Temizlendi",
        closeTimeout: 1000,
        position: "center",
        icon: "<i class='f7-icons'>checkmark_alt</i>"
      });

      toast.open();
    }
  }
};
</script>

<style lang="less">
.block-span {
  display: block;
  width: 100%;
}
</style>