<template>
  <f7-page name="home">
    <f7-navbar title="Yazar" back-link @click="$f7router.back()">
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <div v-if="author">
      <f7-block-title>{{ author.author.name }}</f7-block-title>
      <f7-list media-list v-if="author.books.length" style="margin-top: 0">
        <f7-list-item
          class="search-item"
          v-for="(book, index) in author.books"
          :key="index + book.isbn"
          :title="book.name"
          :link="'/book/' + book.isbn"
          @click="$f7.preloader.show()"
          :subtitle="author.author.name"
        >
          <img slot="media" :src="book.image" width="80"
               onerror="this.onerror=null;this.src='static/pintikitap_placeholder.jpg'"
          />
          <span slot="text">{{
            book.publisher.name ? book.publisher.name : ""
          }}</span>
          <span slot="footer">
            <f7-chip class="float-right" :class="{'chip-outline color-red': !book.minPrice}" :text="book.minPrice ? book.minPrice + ' ₺' : 'tükendi'" />
          </span>
        </f7-list-item>

        <template v-if="author.last_page != author.page">
          <f7-list-item divider></f7-list-item>
          <f7-list-item title="Daha fazla sonuç yükle" @click="searchPage" />
        </template>
        <f7-list-item
          v-else
          divider
          title="Tüm kitaplar yüklendi"
        ></f7-list-item>
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
export default {
  name: "Author",
  data() {
    return {
      author: null,
    };
  },
  methods: {
    async searchPage() {
      const page = parseInt(this.author.page) + 1;
      this.$f7.preloader.show();
      const r = await this.fetch(
        `/author-books/${this.$f7route.params.id}/${page}`
      );
      this.$f7.preloader.hide();
      this.author.books = [...this.author.books, ...r.books];
      this.author.page = r.page;
    },
    async load() {
      this.$f7.preloader.show();
      const r = await this.fetch(`/author-books/${this.$f7route.params.id}`);
      this.$f7.preloader.hide();

      return r;
    },
  },
  async created() {
    this.author = await this.load();
  },
};
</script>

<style lang="less" scoped>
.search-margin {
  margin: -20px 0;
  padding-left: 0;
  padding-right: 0;
}
.chip {
  margin-right: 5px;
}
</style>