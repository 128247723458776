<template>
  <f7-page name="home">
    <f7-navbar title="Yazarlar" back-link @click="$f7router.back()">
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block class="search-margin">
      <f7-list inline-labels no-hairlines-md>
        <f7-list-input :input="false">
          <input
            ref="search_input"
            type="text"
            placeholder="Yazar Bul"
            slot="input"
            v-model="query"
            @keyup.enter="search"
            outline
          />
          <i class="icon f7-icons" @click="search" slot="inner-end">search</i>
        </f7-list-input>
      </f7-list>
    </f7-block>

    <f7-list style="margin: 0">
      <f7-list-item
        title="Harf değiştir"
        smart-select
        :smart-select-params="{
          openIn: 'sheet',
          sheetCloseLinkText: 'Kapat',
          closeOnSelect: true,
          cssClass: 'letter-select',
        }"
      >
        <select name="letter" v-model="letter" @change="loadFirst">
          <option v-for="l in letters" :key="l" :value="l">{{ l }}</option>
        </select>
      </f7-list-item>
      <f7-list-item divider :title="`Yazarlar (${letter})`"></f7-list-item>
      <f7-list-item
        v-for="author in authors.authors"
        :key="author.id"
        :title="author.name"
        :link="`/author/${author.id}`"
      />

      <f7-list-item divider></f7-list-item>
      <f7-list-item title="Daha fazla sonuç yükle" @click="loadMore" />
    </f7-list>
    <br />
  </f7-page>
</template>

<script>
export default {
  name: "Authors",
  data() {
    return {
      query: "",
      letter: "A",
      letters: [
        "A",
        "B",
        "C",
        "Ç",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "İ",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "Ö",
        "P",
        "R",
        "S",
        "Ş",
        "T",
        "U",
        "Ü",
        "V",
        "Y",
        "Z",
        "X",
        "Q",
        "W",
      ],
      authors: [],
    };
  },
  methods: {
    async search() {
      this.$f7.preloader.show();
      const r = await this.fetch(`/author-search/?q=${this.query}`);
      this.authors = r;
      this.$f7.preloader.hide();
    },
    async loadMore() {
      const authors = await this.load(1 + parseInt(this.authors.page));
      this.authors.page = authors.page;
      this.authors.authors = [...this.authors.authors, ...authors.authors];
    },
    async loadFirst() {
      this.authors = await this.load(1);
    },
    async load(page) {
      if (!page) {
        page = 1;
      }
      this.$f7.preloader.show();
      const r = await this.fetch(
        `/author-starts-with/${this.letter}?page=${page}`
      );
      this.$f7.preloader.hide();

      return r;
    },
  },
  async created() {
    this.authors = await this.load();
  },
};
</script>

<style lang="less" scoped>
.search-margin {
  margin: -20px 0;
  padding-left: 0;
  padding-right: 0;
}
.chip {
  margin-right: 5px;
}
</style>