export default {
  _key: 'recent_searches',
  add(k) {
    let items = this.getAll()
    if (this.contains(k)) {
      this.remove(k)
      items = this.getAll()
    }

    items.unshift(k)

    if (items.length === 11) {
      items.splice(-1, 1); // remove last item
    }

    this.save(items)
  },
  getAll() {
    return JSON.parse(localStorage.getItem(this._key) || '[]')
  },

  remove(k) {
    const items = this.getAll()

    const index = items.indexOf(k);
    if (index > -1) {
      items.splice(index, 1);
    }

    this.save(items);
  },

  save(items) {
    localStorage.setItem(this._key, JSON.stringify(items))
  },

  contains(k) {
    return this.getAll().indexOf(k) > -1;
  }
}