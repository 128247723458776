
import HomePage from '../pages/home.vue';
import BookPage from '../pages/Book.vue';
import SearchResultsPage from '../pages/SearchResults.vue';
import SearchPage from '../pages/Search.vue';
import ProfilePage from '../pages/Profile.vue';
import FavoritesPage from '../pages/Favorites';
import OptionsPage from '../pages/Options';
import AuthorsPage from '../pages/Authors';
import AuthorPage from '../pages/Author';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    name: 'Home',
    path: '/',
    component: HomePage,
    keepAlive: true,
  },
  {
    name: 'Profile',
    path: '/profile',
    component: ProfilePage,
  },
  {
    name: 'Book',
    path: '/book/:isbn',
    component: BookPage,
  },
  {
    name: 'Search',
    path: '/search',
    component: SearchPage,
  },
  {
    name: 'SearchResults',
    path: '/search/:query',
    component: SearchResultsPage,
  },
  {
    name: 'Favorites',
    path: '/favorites',
    component: FavoritesPage,
  },
  {
    name: 'Options',
    path: '/options',
    component: OptionsPage,
  },
  {
    name: 'Authors',
    path: '/authors',
    component: AuthorsPage,
  },
  {
    name: 'Author',
    path: '/author/:id',
    component: AuthorPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
