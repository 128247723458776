<template>
  <f7-app :params="f7params" :class="darkMode">
    <!-- Your main view, should have "view-main" class -->
    <f7-view main class="safe-areas" url="/">
      <f7-toolbar tabbar position="bottom">
        <f7-link
          href="/"
          class="tab-link"
          :class="{ 'tab-link-active': currentRoute === 'Home' }"
          icon-ios="f7:house"
          icon-aurora="f7:home"
          icon-md="material:home"
          ></f7-link
        >
        <f7-link
          class="tab-link"
          :class="{ 'tab-link-active': currentRoute === 'Authors' }"
          href="/authors"
          icon-ios="f7:pencil_ellipsis_rectangle"
          icon-aurora="f7:pencil_ellipsis_rectangle"
          icon-md="f7:pencil_ellipsis_rectangle"
          ></f7-link
        >

        <f7-link
          class="tab-link"
          :class="{ 'tab-link-active': currentRoute === 'Search' }"
          href="/search"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
          ></f7-link
        >
        <f7-link
          class="tab-link"
          :class="{ 'tab-link-active': currentRoute === 'Profile' }"
          href="/profile"
          icon-ios="f7:person"
          icon-aurora="f7:person"
          icon-md="material:person_alt"
          ></f7-link
        >
      </f7-toolbar>
    </f7-view>
  </f7-app>
</template>
<script>
import { Device } from "framework7/framework7-lite.esm.bundle.js";
import cordovaApp from "../js/cordova-app.js";
import routes from "../js/routes.js";

export default {
  name: "App",
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        dialog: {
          title: "Pinti Kitap",
          buttonOk: "Evet",
          buttonCancel: "Hayır",
        },
        id: "com.sigateknoloji.pintikitap", // App bundle ID
        name: "Pinti Kitap", // App name
        theme: "auto", // Automatic theme detection
        // App routes
        routes: routes,
        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
          androidBackgroundColor: "#34495e",
          androidTextColor: "white",
        },
      },
    };
  },
  computed: {
    darkMode() {
      if (this.$store.state.darkMode) {
        return "theme-dark";
      }
      return "theme-light";
    },
    currentRoute() {
      return this.$store.state.currentRoute;
    },
  },
  mounted() {
    this.$f7ready((f7) => {
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        if (navigator.connection.type === Connection.NONE) {
          this.$f7.dialog.alert("İnternet bağlantınız yok.", "Hata", () => {
            navigator.app.exitApp();
          });
        }

        cordovaApp.init(f7);
        universalLinks.subscribe("openBook", (event) => {
          // {"url":"http://kitapp.site/book/1234","host":"kitapp.site","scheme":"http","path":"/book/1234","params":{}}
          const isbn = event.path.split("/").pop();
          this.$store.dispatch("goBook", isbn);
        });

        const sync = () => {
          codePush.sync(null, {
            updateDialog: false,
            installMode: InstallMode.IMMEDIATE,
          });
        };
        document.addEventListener("deviceready", sync, false);
        document.addEventListener("resume", sync);

        document.addEventListener(
          "backbutton",
          () => {
            if (window.ScannerOpened) {
              return;
            }

            if (window.storyOpened) {
              zuck.modal.close();
              return;
            }

            if (this.$store.state.currentRoute === "Home") {
              navigator.app.exitApp();
            }
          },
          false
        );

        const push = PushNotification.init({
          android: {},
          ios: {
            alert: "true",
            badge: "true",
            sound: "true",
          },
          windows: {},
        });

        push.on("registration", (data) => {
          console.log("registration", data);
        });

        push.on("notification", (data) => {
          console.log("notification", data);
          // data.message,
          // data.title,
          // data.count,
          // data.sound,
          // data.image,
          // data.additionalData
        });

        push.on("error", (e) => {
          console.log("error", "e");
          // e.message
        });
      }
      // Call F7 APIs here

      this.$f7.views.main.router.on("routeChange", (newRoute) => {
        this.$nextTick(() => {
          this.$store.commit("setCurrentRoute", newRoute.name);
        });
      });

      const darkMode = localStorage.getItem("darkMode");
      if (darkMode !== null) {
        this.$store.commit("setDarkMode", parseInt(darkMode));
      } else {
        this.$store.commit("setDarkMode", 0);
      }
    });
  },
};
</script>
