<template>
  <f7-page name="book">
    <!-- Top Navbar -->
    <f7-navbar back-link @click="$f7router.back()">
      <f7-nav-title class="navbar-book-title">{{ book.name }}</f7-nav-title>
    </f7-navbar>
    <div v-if="book.image" class="image-bg" :style="imageBgStyle"></div>
    <div v-if="book.image" class="image-wrapper">
      <img
          src="static/pintikitap_placeholder.jpg"
          class="book-image lazy lazy-fade-in"
          :data-src="book.image"
          onerror="this.onerror=null;this.src='static/pintikitap_placeholder.jpg'"
      />
    </div>

    <span class="cheap-price" v-if="book.minPrice">{{ book.minPrice }} ₺</span>
    <span class="cheap-price cheap-price-red" v-else>tükendi</span>

    <div style="border-bottom: 1px solid #ccc"></div>
    <f7-block-title class="book-name">{{ book.name }}</f7-block-title>
    <f7-row class="text-align-center margin">
      <f7-col>
        <f7-button fill>{{ book.author.name }}</f7-button>
      </f7-col>
      <f7-col>
        <f7-button fill>{{ book.publisher.name }}</f7-button>
      </f7-col>
    </f7-row>

    <f7-block strong>
      <f7-row class="text-align-center">
        <f7-col @click="addToFavorites" v-if="!isFavoriteBook">
          <f7-icon f7="star"></f7-icon>
          <br />
          <small>Favorilere ekle</small>
        </f7-col>
        <f7-col v-else @click="removeFromFavorites">
          <f7-icon f7="star_fill"></f7-icon>
          <br />
          <small>Favorilerden Çıkar</small>
        </f7-col>
        <f7-col @click="share">
          <f7-icon f7="paperplane"></f7-icon>
          <br />
          <small>Paylaş</small>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block style="margin: 10px 0">
      <f7-segmented strong>
        <f7-button tab-link-active tab-link="#tab-1"
          >Fiyatlar ({{ book.prices2.length }})</f7-button
        >
        <f7-button tab-link="#tab-2">Açıklama</f7-button>
      </f7-segmented>
    </f7-block>
    <f7-tabs>
      <f7-tab id="tab-1" tab-active>
        <f7-block style="margin-top: 0">
          <f7-list style="margin-top: 0">
            <f7-list-item
              v-for="(price, index) in book.prices2"
              :key="index"
              :title="price.shop"
              :after="price.price + '₺'"
              target="_blank"
              @click.prevent="goToProvider(price.url)"
              link
            >
            <object
            :data="shopImage(price.shop)" type="image/png"
            slot="media"
            class="provider-logo"
            >
              <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=" alt="Stack Overflow logo and icons and such" class="provider-logo">
          </object>  
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-2">
        <f7-card>
          <f7-card-header>
            <b>{{ book.name }}</b>
          </f7-card-header>
          <f7-card-content
            v-if="book.description"
            v-html="book.description.replace('<p></p>', '')"
            class="book-description"
          ></f7-card-content>
        </f7-card>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import { mapState } from "vuex";
import favorites from "../js/favorites";
import slug from "slug";
export default {
  name: "Book",
  data() {
    return {
      isbn: "",
      book: {
        author: {},
        category: {},
        price: [],
        publisher: {},
        prices2: {},
      },
      reRender: 0,
      prices: [],
    };
  },
  methods: {
    shopImage(str) {
      return "static/logos/" + slug(str) + ".png";
    },
    addToFavorites() {
      this.reRender++;
      favorites.add(this.isbn);
      const toast = this.$f7.toast.create({
        text: "Favorilere eklendi",
        closeTimeout: 2000,
      });

      toast.open();
    },
    removeFromFavorites() {
      this.reRender++;
      favorites.remove(this.isbn);
    },
    goToProvider(url) {
      window.open(url, "_system");
      return false;
    },
    sortPrices() {
      this.book.price.sort((left, right) => {
        const salePrice = parseFloat(left.sale_price);
        const listPrice = parseFloat(right.sale_price);
        if (salePrice < listPrice) {
          return -1;
        }

        if (salePrice > listPrice) {
          return 1;
        }

        return 0;
      });
    },
    share() {
      this.$store.dispatch("shareBook", [this.book]);
    },
  },
  created() {
    this.$store.commit("setCurrentRoute", "Book");
    this.isbn = this.$f7route.params.isbn;

    this.fetch(`/book/${this.isbn}`)
      .then((r) => {
        this.book = r;
        this.$nextTick(() => {
          this.$f7.lazy.loadImage(".book-image");
          this.$f7.preloader.hide();
        });
      })
      .catch((e) => {
        this.$f7.preloader.hide();

        this.$f7.dialog.alert("Kitap bulunamadı", "Pinti Kitap", () => {
          this.$f7router.clearHistory();
          this.$f7router.navigate("/");
        });
      });
  },
  computed: {
    isFavoriteBook() {
      this.reRender++;
      return favorites.contains(this.isbn);
    },
    cheapestProvider() {
      if (!this.book.price.length) {
        return "";
      }
      return this.book.price[0].provider.name;
    },
    imageBgStyle() {
      return `background-image: url('${this.book.image}')`;
    },
  },
};
</script>

<style lang="less" scoped>
.book-image {
  display: block;
  top: var(--f7-page-navbar-offset, 0px);
  left: 50%;
  margin-left: -80px;
  position: absolute;
  width: 160px;
  height: 250px;
}
.image-bg {
  height: 250px;
  background-color: #ddd;
  background-size: cover;
  background-position-y: center;
  filter: blur(3px);
}
.provider-logo {
  width: 24px;
  height: 24px;
}
.cheap-price {
  text-align: center;
  color: #017401;
  font-weight: 700;
  font-size: 26px;
  width: 100%;
  display: block;
  margin-top: 10px;
}
.cheap-price-red {
  color: #d00101;
}
.sale-price {
  color: #444;
  font-weight: bold;
}
.book-description p {
  margin: 0;
  padding: 0;
}
.navbar-book-title {
  font-size: 17px;
  text-align: left;
}

.book-name {
  text-align: center;
  font-size: 20px;
  color: var(--f7-bars-text-color);
  line-height: initial;
}
</style>