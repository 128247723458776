<template>
  <f7-page name="home">
    <f7-navbar title="Arama sonuçları" back-link @click="$f7router.back()">
      <f7-nav-right>
        <f7-link slot="right" sheet-open=".demo-sheet">
          <f7-icon f7="slider_horizontal_3"></f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-sheet
      class="demo-sheet"
      :opened="sheetOpened"
      @sheet:closed="sheetOpened = false"
      backdrop
      swipe-to-close
      style="height: 50%"
    >
      <f7-toolbar>
        <div class="left"></div>
        <div class="right">
          <f7-link sheet-close @click="cleanFilters" style="color: #c00000">
            <f7-icon f7="multiply_square_fill"></f7-icon> Filtreleri Temizle
          </f7-link>
        </div>
      </f7-toolbar>

      <f7-list style="margin-top: 5px">
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Yazar</div>
            <div class="item-input-wrap">
              <input
                id="autocomplete-author"
                type="text"
                placeholder="Yazar seçin"
                v-model="filter.author"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Yayınevi</div>
            <div class="item-input-wrap">
              <input
                id="autocomplete-publisher"
                type="text"
                placeholder="Yayınevi seçin"
                v-model="filter.publisher"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
      </f7-list>
      <f7-block>
        <f7-button
          large
          raised
          fill
          color="blue"
          text-color="white"
          @click="search"
          sheet-close
          >Uygula</f7-button
        >
      </f7-block>
    </f7-sheet>

    <f7-block-title v-if="result.books.length">
      Aranan: <b>{{ filter.q }}</b>
    </f7-block-title>
    <f7-block class="block-center" v-if="result.books.length === 0 && !loading">
      <div>
        <b>{{ filter.q }}</b> araması için herhangi bir sonuç bulunamadı.
      </div>
    </f7-block>
    <div
      class="page-content infinite-scroll-content"
      data-infinite-distance="70"
      style="padding-top: 0px"
    >
      <div>
        <f7-list media-list v-if="result.books.length" style="margin-top: 0">
          <f7-list-item
            class="search-item"
            v-for="(book, index) in result.books"
            :key="index + book.isbn"
            :title="book.name"
            :link="'/book/' + book.isbn"
            @click="$f7.preloader.show()"
            :subtitle="book.author.name"
          >
            <img slot="media"
                 width="80"
                 :src="book.image"
                 onerror="this.onerror=null;this.src='static/pintikitap_placeholder.jpg'"
            />
            <span slot="text">{{ book.publisher.name }}</span>
            <span slot="footer">
              <f7-chip class="float-right" :class="{'chip-outline color-red': !book.minPrice}" :text="book.minPrice ? book.minPrice + ' ₺' : 'tükendi'" />
            </span>
          </f7-list-item>
        </f7-list>
      </div>
      <div
        v-show="result.books.length !== 0 && !loading"
        class="preloader infinite-scroll-preloader"
      ></div>
    </div>
  </f7-page>
</template>

<script>
import buildQuery from "./../js/buildquery";

export default {
  name: "SearchResults",
  data() {
    return {
      loading: true,
      query: "",
      result: {
        books: [],
      },
      sheetOpened: false,
      filter: {
        q: "",
        p: 1,
        author: "",
        publisher: "",
      },
    };
  },
  methods: {
    cleanFilters() {
      this.filter.author = this.filter.publisher = "";
      this.search();
    },
    search() {
      this.loading = true;
      this.$f7.preloader.show();
      const query = buildQuery(this.filter);

      return this.fetch(`/search?${query}`).then((r) => {
        this.result.books = r.books;
        this.$f7.preloader.hide();
        this.$nextTick(() => {
          console.log("mounted")
          this.$f7.lazy.create(".lazy-image");
        });
        this.loading = false;
        if (r.books.length < 10) {
          this.$f7.$(".infinite-scroll-preloader").remove();
        }
      });
    },
    searchInfinity() {
      const query = buildQuery(this.filter);

      return this.fetch(`/search?${query}`).then((r) => {
        this.result.books = this.result.books.concat(r.books);
      });
    },
    autocompleteAuthor() {
      const self = this;
      const x = this.$f7.autocomplete.create({
        inputEl: "#autocomplete-author",
        openIn: "dropdown",
        preloader: true, //enable preloader
        /* If we set valueProperty to "id" then input value on select will be set according to this property */
        valueProperty: "name", //object's "value" property name
        textProperty: "name", //object's "text" property name
        limit: 5, //limit to 20 results

        source: function (query, render) {
          var autocomplete = this;
          var results = [];
          if (query.length === 0) {
            render(results);
            return;
          }
          // Show Preloader
          autocomplete.preloaderShow();

          // Do Ajax request to Autocomplete data
          self.$f7.request({
            url: self.uri("/search_as/author"),
            method: "GET",
            dataType: "json",
            //send "query" to server. Useful in case you generate response dynamically
            data: {
              query: query,
            },
            success: function (data) {
              // Find matched items
              for (var i = 0; i < data.length; i++) {
                if (
                  data[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0
                )
                  results.push(data[i]);
              }
              // Hide Preoloader
              autocomplete.preloaderHide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
      });
    },
    autocompletePublisher() {
      const self = this;
      const x = this.$f7.autocomplete.create({
        inputEl: "#autocomplete-publisher",
        openIn: "dropdown",
        preloader: true, //enable preloader
        /* If we set valueProperty to "id" then input value on select will be set according to this property */
        valueProperty: "name", //object's "value" property name
        textProperty: "name", //object's "text" property name
        limit: 5, //limit to 20 results
        source: function (query, render) {
          var autocomplete = this;
          var results = [];
          if (query.length === 0) {
            render(results);
            return;
          }
          // Show Preloader
          autocomplete.preloaderShow();

          // Do Ajax request to Autocomplete data
          self.$f7.request({
            url: self.uri("/search_as/publisher"),
            method: "GET",
            dataType: "json",
            //send "query" to server. Useful in case you generate response dynamically
            data: {
              query: query,
            },
            success: function (data) {
              // Find matched items
              for (var i = 0; i < data.length; i++) {
                if (
                  data[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0
                )
                  results.push(data[i]);
              }
              // Hide Preoloader
              autocomplete.preloaderHide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
      });
    },
    scroll() {
      let allowInfinite = true;
      let lastItemIndex = 10;
      let maxItems = 50;

      this.$f7.$(".infinite-scroll-content").on("infinite", () => {
        // Exit, if loading in progress
        if (false === allowInfinite) {
          return;
        }

        if (lastItemIndex >= maxItems) {
          this.$f7.infiniteScroll.destroy(".infinite-scroll-content");
          // Remove preloader
          this.$f7.$(".infinite-scroll-preloader").remove();

          return;
        }
        // Set loading flag
        allowInfinite = false;

        this.filter.p += 1;
        // Emulate 1s loading
        this.searchInfinity().then((_) => {
          allowInfinite = true;
          lastItemIndex += 10;
        });
      });
    },
  },
  created() {
    this.$store.commit("setCurrentRoute", "Search");
    this.filter.q = this.$f7route.params.query;
    this.search();
  },
  mounted() {
    this.autocompleteAuthor();
    this.autocompletePublisher();
    this.scroll();
  },
};
</script>

<style lang="less">
.search {
  color: red !important;
  z-index: 99;
}
</style>