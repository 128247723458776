import Vue from 'vue'
import VueLocalStorage from 'vue-localstorage'

Vue.use(VueLocalStorage)

export default {
  _key: 'books',
  add(isbn) {
    const items = this.getAll()

    items.push(isbn)

    this.save(items)
  },

  getAll() {
    return JSON.parse(Vue.localStorage.get(this._key, '[]'))
  },

  remove(isbn) {
    const items = this.getAll()

    const index = items.indexOf(isbn);
    if (index > -1) {
      items.splice(index, 1);
    }

    this.save(items);
  },

  save(items) {
    Vue.localStorage.set(this._key, JSON.stringify(items))
  },

  contains(isbn) {
    return this.getAll().indexOf(isbn) > -1;
  }
}