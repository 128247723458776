<template>
  <f7-block strong>
    <f7-block-title class="pk-block-title">{{ title }}</f7-block-title>
    <f7-swiper
      v-if="!items.length"
      :params="swiperOpts"
      style="margin: 0 -10px"
    >
      <f7-swiper-slide
        style="width: 30%"
        v-for="item in 6"
        :key="item"
        class="skeleton-text skeleton-effect-fade"
      >
        <f7-card>
          <f7-card-content :padding="false">
            <img
              src="static/pintikitap_placeholder.jpg"
              class="popularbook-image lazy lazy-fade-in"
              @click="goBook(book.isbn)"
            />
            <span class="span-block">Author Name</span>
            <span class="span-price">00.00</span>
          </f7-card-content>
        </f7-card>
      </f7-swiper-slide>
    </f7-swiper>
    
    <f7-swiper v-else :params="swiperOpts" style="margin: 0 -10px">
      <f7-swiper-slide
        v-for="(book, index) in items"
        :key="index"
        style="width: 30%"
      >
        <f7-card :key="book.isbn">
          <f7-card-content :padding="false">
            <img
              src="static/pintikitap_placeholder.jpg"
              :data-src="uri(`/image/${book.isbn}`)"
              class="popularbook-image lazy lazy-fade-in"
              @click="goBook(book.isbn)"
            />
            <span class="span-block" @click="goBook(book.isbn)">{{
              book.author.name
            }}</span>
            <span class="span-price" @click="goBook(book.isbn)"
              >{{ book.minPrice }} ₺</span
            >
          </f7-card-content>
        </f7-card>
      </f7-swiper-slide>
    </f7-swiper>
  </f7-block>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Swiper",
  props: ["title", "items"],
  data() {
    return {
      swiperOpts: {
        spaceBetween: 0,
        slidesPerView: "auto",
        freeMode: true,
        freeModeMomentumRatio: 0.4,
        preloadImages: true,
        preventClicks: false,
      },
    };
  },
  methods: {
    ...mapActions(["goBook"]),
  },
  created() {},
};
</script>

<style lang="less" scoped>
.span-block,
.span-price {
  display: block;
  text-align: center;
}
.span-block {
  text-align: center;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.theme-dark .span-price {
  background-color: #333;
}
.span-price {
  background-color: #eee;
  padding: 3px 0;

  margin-top: 10px;
  font-weight: bold;
}
.popularbook-image {
  width: 100%;
}
</style>