<template>
  <f7-page name="home">
    <f7-navbar title="Arama" back-link @click="$f7router.back()">
      <f7-nav-right>
        <f7-link
          icon-ios="f7:barcode_viewfinder"
          icon-aurora="f7:barcode_viewfinder"
          icon-md="f7:barcode_viewfinder"
          @click="openScanner"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block class="search-margin">
      <f7-list inline-labels no-hairlines-md>
        <f7-list-input :input="false">
          <input
            ref="search_input"
            type="text"
            placeholder="Kitap Bul"
            slot="input"
            v-model="query"
            @keyup.enter="search"
            outline
          />
          <i class="icon f7-icons" @click="search" slot="inner-end">search</i>
        </f7-list-input>
      </f7-list>
    </f7-block>

    <f7-block strong v-if="searches.length">
      <f7-block-title class="pk-block-title margin-bottom">
        Arama geçmişi
        <div class="float-right" @click="clearSearches" v-if="searches.length">
          <f7-icon size="18" f7="trash"></f7-icon>
        </div>
      </f7-block-title>
      <div class="chip" v-for="(item, index) in searches" :key="index">
        <div class="chip-label" @click="performSearch(item)">{{ item }}</div>
        <a @click="deleteSearch(item)" class="chip-delete"></a>
      </div>
    </f7-block>

    <f7-block strong>
      <f7-block-title class="pk-block-title margin-bottom"
        >Popüler aramalar</f7-block-title
      >
      <div class="text-align-center">
        <f7-preloader v-if="loadingPopular"></f7-preloader>
      </div>
      <f7-chip
        v-for="(item, index) in popularSearches"
        :key="index"
        :outline="item.outline"
        :text="item.name"
        :color="item.color"
        @click="performSearch(item.name)"
      ></f7-chip>
    </f7-block>
  </f7-page>
</template>

<script>
import RecentSearch from "../js/recent_searches";

export default {
  name: "Search",
  data() {
    return {
      query: "",
      searches: [],
      popularSearches: [],
    };
  },
  methods: {
    search() {
      if (this.query === "") {
        return;
      }
      this.$refs.search_input.blur();
      this.performSearch(this.query);
    },
    performSearch(q) {
      RecentSearch.add(q);
      q = encodeURIComponent(q);
      this.$f7router.navigate(`/search/${q}`);
      setTimeout(() => (this.searches = RecentSearch.getAll()), 100);
    },
    deleteSearch(k) {
      RecentSearch.remove(k);
      this.searches = RecentSearch.getAll();
    },
    randomColor() {
      const colors = ["", "green", "red", "blue", "orange", "pink", "black"];

      return colors[Math.floor(Math.random() * colors.length)];
    },
    randomBoolean() {
      return Math.random() >= 0.5;
    },
    clearSearches() {
      this.$f7.dialog.confirm(
        "Geçmişi temizlemek istiyor musun?",
        "Pinti Kitap",
        () => {
          RecentSearch.save([]);
          this.searches = RecentSearch.getAll();
        },
        noop
      );
    },
    openScanner() {
      window.ScannerOpened = true;
      cordova.plugins.barcodeScanner.scan(this.onResult, this.onError);
    },
    onResult(result) {
      window.ScannerOpened = false;

      if (result.cancelled) {
        return;
      }

      const isbn = result.text;
      this.$store.dispatch("goBook", encodeURIComponent(isbn));
    },
    onError(error) {
      self.$nextTick(() => {
        this.$f7.dialog.alert(error);
      });
    },
  },
  async created() {
    this.searches = RecentSearch.getAll();
    this.loadingPopular = true;

    this.popularSearches = await this.fetch("/popular-search");

    this.loadingPopular = false;
  },
  mounted() {
    this.$f7.searchbar.toggle();
  },
};
</script>

<style lang="less" scoped>
.search-margin {
  margin: -20px 0;
  padding-left: 0;
  padding-right: 0;
}
.chip {
  margin-right: 5px;
}
</style>