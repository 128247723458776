import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        darkMode: 0,
        currentRoute: "Home",
    },
    mutations: {
        setCurrentRoute(state, newRoute) {
            state.currentRoute = newRoute;
        },
        setDarkMode(state, val) {
            state.darkMode = val
            localStorage.setItem("darkMode", val)
        }
    },
    actions: {
        shareBook(context, [book]) {
            if (!window.cordova) {
                return;
            }
            const options = {
                message: `İşte senin için bir kitap ${book.name}`, // not supported on some apps (Facebook, Instagram)
                subject: "the subject", // fi. for emails
                url: `https://pintikitap.com/book/${book.uuid}`,
            };

            const onSuccess = function (result) {
                this._vm.$f7.toast.create("Kitap paylaşıldı").open();
            };

            const onError = function (msg) {
            };

            window.plugins.socialsharing.shareWithOptions(
                options,
                onSuccess,
                onError
            );
        },
        async goBook(context, isbn) {
            const app = this._vm.$f7;
            app.preloader.show();

            this._vm.$f7.views.main.router.navigate(`/book/${isbn}`);
        }
    },
})