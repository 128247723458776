<template>
  <f7-page name="home">
    <f7-navbar title="Profil" back-link @click="$f7router.back()">
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list class="mt-0">
      <f7-list-item title="Favori Kitaplarım" link="/favorites">
        <f7-icon slot="media" f7="star"></f7-icon>
      </f7-list-item>
      <f7-list-item title="Ayarlar" link="/options">
        <f7-icon slot="media" f7="gear_alt_fill"></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  name: "Profile"
};
</script>

<style lang="less">
</style>