<template>
  <f7-page name="home">
    <f7-navbar :title="'Favori Kitaplarım ('+ bookCount + ')'" back-link @click="$f7router.back()">
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-list media-list v-if="result !== null" class="mt-0">
      <f7-list-item
        v-for="book in result.books"
        :key="book.isbn"
        :title="book.name"
        :link="'/book/' + book.isbn"
        :subtitle="book.author.name"
        swipeout
        @swipeout:deleted="onDeleted(book.isbn)"
        ref="first"
      >
        <f7-swipeout-actions right>
          <f7-swipeout-button delete>Sil</f7-swipeout-button>
        </f7-swipeout-actions>
        <img slot="media" :src="book.image" width="80" />
        <span slot="text">{{ book.publisher.name }}</span>
        <span slot="footer">
          <f7-chip class="float-right" :text="book.minPrice + ' ₺'" />
        </span>
      </f7-list-item>
    </f7-list>
    <f7-block v-if="bookCount === 0" class="block-center">
      <div>Favori listeniz boş.</div>
    </f7-block>
  </f7-page>
</template>

<script>
import favorites from "../js/favorites";

export default {
  name: "Favorites",
  data() {
    return {
      query: "",
      result: {
        books: {}
      },
      isEmpty: false,
      bookCount: 0
    };
  },
  methods: {
    fetchFavorites(items) {
      if (!items.length) {
        return;
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ books: items.reverse() })
      };
      this.fetch(`/favorites`, options).then(r => {
        this.result = r;
        this.$f7.dialog.close();
      });
    },
    onDeleted(isbn) {
      favorites.remove(isbn);
      this.bookCount--;
    }
  },
  created() {
    this.$store.commit("setCurrentRoute", "Favorites");
    const favoriteBooks = favorites.getAll();
    this.fetchFavorites(favoriteBooks);
    this.bookCount = favoriteBooks.length;
  }
};
</script>

<style lang="less">
</style>