// Import Vue
import Vue from 'vue';
// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';
import 'zuck.js/dist/zuck.css';
import 'zuck.js/dist/skins/facesnap.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';
import '../css/zuck.js.overrides.css';

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

window.noop = () => { }

import store from './store'

Vue.prototype.uri = (path) => {
  return `${process.env.HOST}/api${path}`;
};

Vue.prototype.fetch = function (path, options = {}) {
  return fetch(this.uri(path), options).then(r => r.json());
};

Vue.prototype.$fetch = function (path) {
  return fetch(this.uri(path));
};

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
  store,
});
