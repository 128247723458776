<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar class="pk-navbar" title="Pinti Kitap" />

    <!-- Page content-->
    <Stories style="margin:10px 10px 0;" />
    <Swiper :items="popular_books" title="Çok Satanlar" />
    <Swiper :items="latest_books" title="Yeni Çıkanlar" />

  </f7-page>
</template>

<script>
import Stories from "../components/Stories";
import Swiper from "../components/Swiper";

export default {
  name: "Home",
  components: {
    Stories,
    Swiper
  },
  data() {
    return {
      popular_books: [],
      latest_books: [],
      isbn: "",
      book: "",
      prices: [],
      result: null,
      swiperOpts: {
        spaceBetween: 0,
        slidesPerView: "auto",
        freeMode: true,
        freeModeMomentumRatio: 0.4,
        preloadImages: true,
        preventClicks: false,
      },
    };
  },
  methods: {
    share() {
      this.$store.dispatch("shareBook", [this.book]);
    },
  },
  created() {
    this.$store.commit("setCurrentRoute", "Home");

    this.fetch("/popular").then((r) => {
      this.popular_books = r;
      this.$nextTick(() => {
        this.$f7.lazy.create(".popularbook-image");
      });
    });
    this.fetch("/latest_books").then((r) => {
      this.latest_books = r;
      this.$nextTick(() => {
        this.$f7.lazy.create(".popularbook-image");
      });
    });
  },
};
</script>