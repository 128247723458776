<template>
  <div id="stories"></div>
</template>

<script>
import Zuck from "../js/zuck";

export default {
  name: "Stroies",
  data() {
    return {
      providers: [],
    };
  },
  methods: {
    initStories() {
      const stories = [];

      this.providers.forEach((provider) => {
        const items = [];

        provider.campaigns.forEach((campaign) => {
          items.push([
            `${provider.name}-${campaign.id}`, // id
            "photo", // type (photo || video)
            3, // duration
            campaign.image, // source image
            provider.image, // preview image
            campaign.url, // link
            "Kampanyayı gör", // link text
            false,
            1585347121,
          ]);
        });

        stories.push(
          Zuck.buildTimelineItem(
            provider.name,
            provider.image,
            provider.name,
            provider.url,
            null,
            items
          )
        );
      });

      var get = function get(array, what) {
        if (array) {
          return array[what] || "";
        } else {
          return "";
        }
      };

      const zuck = new Zuck("stories", {
        stories,
        backButton: false,
        backNative: true,
        localStorage: true,
        cubeEffect: false,
        skin: "facesnap",
        callbacks: {
          onOpen(storyId, callback) {
            window.storyOpened = true;
            callback();
          },

          onView(storyId) {
            // on view story
          },

          onEnd(storyId, callback) {
            callback(); // on end story
          },

          onClose(storyId, callback) {
            window.storyOpened = false;
            callback();
          },

          onNavigateItem(storyId, nextStoryId, callback) {
            callback(); // on navigate item of story
          },

          onDataUpdate(currentState, callback) {
            callback(); // use to update state on your reactive framework
          },
        },
        template: {
          timelineItem(itemData) {
            return `
            <div class="story ${get(itemData, "seen") === true ? "seen" : ""}">
              <a class="item-link external" href="${get(itemData, "link")}">
                <span class="item-preview">
                  <img lazy="eager" src="${get(itemData, "photo")}" onerror="this.src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'"/>
                </span>
                <span class="info" itemProp="author" itemScope itemType="http://schema.org/Person">
                  <strong class="name" itemProp="name">${get(
                    itemData,
                    "name"
                  )}</strong>
                  <span class="time">${get(itemData, "lastUpdatedAgo")}</span>
                </span>
              </a>
              <ul class="items"></ul>
            </div>`;
          },
          viewerItemBody(index, currentIndex, item) {
            return `<div
                    class="item ${get(item, "seen") === true ? "seen" : ""} ${
              currentIndex === index ? "active" : ""
            }"
                    data-time="${get(item, "time")}" data-type="${get(
              item,
              "type"
            )}" data-index="${index}" data-item-id="${get(item, "id")}">
                    ${
                      get(item, "type") === "video"
                        ? `<video class="media" muted webkit-playsinline playsinline preload="auto" src="${get(
                            item,
                            "src"
                          )}" ${get(item, "type")}></video>
                        <b class="tip muted">${option(
                          "language",
                          "unmute"
                        )}</b>`
                        : `<img loading="auto" class="media" src="${get(
                            item,
                            "src"
                          )}" ${get(item, "type")} />
                    `
                    }
                    ${
                      get(item, "link")
                        ? `<a class="tip link external" href="${get(
                            item,
                            "link"
                          )}" rel="noopener" target="_blank">
                            ${get(item, "linkText")}
                          </a>`
                        : ``
                    }
                  </div>`;
          },
        },
      });

      window.zuck = zuck;
    },
  },
  created() {
    this.fetch("/providers").then((r) => {
      this.providers = r;
      this.initStories();
    });
  },
};
</script>